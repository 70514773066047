import loadable from '@loadable/component';
import React from 'react';

import config from '../config';

import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';
import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const NewListingAddImportLink = loadable(() =>
  import(/* webpackChunkName: "NewListingAddImportLink" */ '../containers/NewListingAddImportLink/NewListingAddImportLink')
);

const NewListingPageSelectType = loadable(() =>
  import(/* webpackChunkName: "NewListingPageSelectType" */ '../containers/NewListingPageSelectType/NewListingPageSelectType')
);

const pageDataLoadingAPI = getPageDataLoadingAPI();

// const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ '../containers/AboutPage/AboutPage'));
const AuthenticationPage = loadable(() =>
  import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage')
);
const CheckoutPage = loadable(() =>
  import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage')
);
const ContactDetailsPage = loadable(() =>
  import(/* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage')
);
const NewListingPage = loadable(() =>
  import(/* webpackChunkName: "NewListingPage" */ '../containers/NewListingPage/NewListingPage')
);
const EditListingPage = loadable(() =>
  import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage')
);
const EmailVerificationPage = loadable(() =>
  import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage')
);
const VerifyYourEmailPage = loadable(() =>
  import(/* webpackChunkName: "VerifyYourEmailPage" */ '../containers/VerifyYourEmailPage/VerifyYourEmailPage')
);
const VerifyYourPhonePage = loadable(() =>
  import(/* webpackChunkName: "VerifyYourPhonePage" */ '../containers/VerifyYourPhonePage/VerifyYourPhonePage')
);
const VerifyYourBusinessPage = loadable(() =>
  import(/* webpackChunkName: "VerifyYourBusinessPage" */ '../containers/VerifyYourBusinessPage/VerifyYourBusinessPage')
);
const AcceptTermsOfServicePage = loadable(() =>
  import(
    /* webpackChunkName: "AcceptTermsOfServicePage" */ '../containers/AcceptTermsOfServicePage/AcceptTermsOfServicePage'
  )
);
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'));
const LandingPage = loadable(() =>
  import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage')
);
const ListingPage = loadable(() =>
  import(/* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPage')
);
const ListingStageInfoPage = loadable(() =>
  import(/* webpackChunkName: "ListingStageInfoPage" */ '../containers/ListingStageInfoPage/ListingStageInfoPage')
);
const ListingOrderPage = loadable(() =>
  import(/* webpackChunkName: "OrderPage" */ '../containers/ListingOrderPage/ListingOrderPage')
);
const ListingOrderProcessingPage = loadable(() =>
  import(
    /* webpackChunkName: "OrderProcessingPage" */ '../containers/ListingOrderProcessingPage/ListingOrderProcessingPage'
  )
);
const ListingOrderInformationPage = loadable(() =>
  import(
    /* webpackChunkName: "OrderDetailsPage" */ '../containers/ListingOrderInformationPage/ListingOrderInformationPage'
  )
);
const ListingOrderStatusPage = loadable(() =>
  import(/* webpackChunkName: "OrderStatusPage" */ '../containers/ListingOrderStatusPage/ListingOrderStatusPage')
);
const ListingOrderSellerAddressPage = loadable(() =>
  import(
    /* webpackChunkName: "OrderSellerAddressPage" */ '../containers/ListingOrderSellerAddressPage/ListingOrderSellerAddressPage'
  )
);
const ListingOrderCourierRequestPage = loadable(() =>
  import(
    /* webpackChunkName: "OrderCourierRequestPage" */ '../containers/ListingOrderCourierRequestPage/ListingOrderCourierRequestPage'
  )
);
const PackagingInstructionsPage = loadable(() =>
  import(
    /* webpackChunkName: "PackagingInstructionsPage" */ '../containers/PackagingInstructionsPage/PackagingInstructionsPage'
  )
);
const PurchasesListPage = loadable(() =>
  import(/* webpackChunkName: "PurchasesListPage" */ '../containers/PurchasesListPage/PurchasesListPage')
);
const SalesListPage = loadable(() =>
  import(/* webpackChunkName: "SalesListPage" */ '../containers/SalesListPage/SalesListPage')
);
const PasswordChangePage = loadable(() =>
  import(/* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage')
);
const PasswordRecoveryPage = loadable(() =>
  import(/* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage')
);
const PasswordResetPage = loadable(() =>
  import(/* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage')
);
const PaymentMethodsPage = loadable(() =>
  import(/* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage')
);
const PrivacyPolicyPage = loadable(() =>
  import(/* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage')
);
const ProfilePage = loadable(() =>
  import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage')
);
const WalletPage = loadable(() => import(/* webpackChunkName: "WalletPage" */ '../containers/WalletPage/WalletPage'));
const ProfileSettingsPage = loadable(() =>
  import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage')
);
const SearchPageWithMap = loadable(() =>
  import(
    /* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */ '../containers/SearchPage/SearchPageWithMap'
  )
);
const SearchPageWithList = loadable(() =>
  import(
    /* webpackChunkName: "SearchPageWithList" */ /* webpackPrefetch: true */ '../containers/SearchPage/SearchPageWithList'
  )
);
const StripePayoutPage = loadable(() =>
  import(/* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage')
);
const TermsOfServicePage = loadable(() =>
  import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage')
);
const TransactionPage = loadable(() =>
  import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage')
);
const FavoriteListingsPage = loadable(() =>
  import(/* webpackChunkName: "TransactionPage" */ '../containers/FavoriteListingsPage/FavoriteListingsPage')
);

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() =>
  import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage')
);

const SearchPage = config.searchPageVariant === 'map' ? SearchPageWithMap : SearchPageWithList;

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

function RedirectToLandingPage() {
  return <NamedRedirect name="LandingPage" />;
}

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    loadData: pageDataLoadingAPI.LandingPage.loadData,
  },
  // {
  //   path: '/about',
  //   name: 'AboutPage',
  //   component: AboutPage,
  // },
  {
    path: '/search/:type?/:category?/:subCategory?',
    name: 'SearchPage',
    component: SearchPage,
    loadData: pageDataLoadingAPI.SearchPage.loadData,
  },
  {
    path: '/search/:type',
    name: 'SearchPageWithType',
    component: SearchPage,
    loadData: pageDataLoadingAPI.SearchPage.loadData,
  },
  {
    path: '/search/:type/:category',
    name: 'SearchPageWithCategory',
    component: SearchPage,
    loadData: pageDataLoadingAPI.SearchPage.loadData,
  },
  {
    path: '/search(/:type)(/:category)(/:subCategory)',
    name: 'SearchPageWithSubCategory',
    component: SearchPage,
    loadData: pageDataLoadingAPI.SearchPage.loadData,
  },
  {
    path: '/listing',
    name: 'ListingBasePage',
    component: RedirectToLandingPage,
  },
  {
    path: '/listing/:slug/:id',
    name: 'ListingPageWithReturn',
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
    extraProps: { showReturnTopBar: true },
  },
  {
    path: '/listing/:slug/:id',
    name: 'ListingPage',
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
    // extraProps: { showReturnTopBar: false }
  },
  {
    path: '/listing/:slug/:id/stage',
    name: 'ListingStageInfoPage',
    component: ListingStageInfoPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/listing/:slug/:id/order',
    name: 'ListingOrderPage',
    auth: true,
    component: ListingOrderPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/listing/order/:orderId/processing',
    name: 'ListingOrderProcessingPage',
    auth: true,
    component: ListingOrderProcessingPage,
  },
  {
    path: '/listing/order/:orderId/info',
    name: 'ListingOrderInformationPage',
    auth: true,
    component: ListingOrderInformationPage,
  },
  {
    path: '/listing/order/:orderId/status',
    name: 'ListingOrderStatusPage',
    auth: true,
    component: ListingOrderStatusPage,
  },
  {
    path: '/listing/order/:orderId/address',
    name: 'ListingOrderSellerAddressPage',
    auth: true,
    component: ListingOrderSellerAddressPage,
  },
  {
    path: '/listing/order/:orderId/courier',
    name: 'ListingOrderCourierRequestPage',
    auth: true,
    component: ListingOrderCourierRequestPage,
  },
  {
    path: '/listing/order/:orderId/packaging',
    name: 'PackagingInstructionsPage',
    auth: true,
    component: PackagingInstructionsPage,
  },
  {
    path: '/listing/:slug/:id/checkout',
    name: 'CheckoutPage',
    auth: true,
    component: CheckoutPage,
    setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
  },
  {
    path: '/listing/:slug/:id/:variant',
    name: 'ListingPageVariant',
    auth: true,
    authPage: 'LoginPage',
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/listing/new',
    name: 'ManualListingPage',
    auth: true,
    component: NewListingPage,
  },
  {
    path: '/listing/import',
    name: 'NewListingAddImportLink',
    auth: true,
    component: NewListingAddImportLink
  },
  {
    path: '/listing/select-listing-type',
    name: 'NewListingPage',
    component: NewListingPageSelectType,
  },
  {
    path: '/listing/:listingType/:slug/:id/:type/:tab',
    name: 'EditListingPage',
    auth: true,
    component: EditListingPage,
    loadData: pageDataLoadingAPI.EditListingPage.loadData,
  },
  {
    path: '/listing/:slug/:id/:type/:tab/:returnURLType',
    name: 'EditListingStripeOnboardingPage',
    auth: true,
    component: EditListingPage,
    loadData: pageDataLoadingAPI.EditListingPage.loadData,
  },

  // Canonical path should be after the `/l/new` path since they
  // conflict and `new` is not a valid listing UUID.
  {
    path: '/listing/:id',
    name: 'ListingPageCanonical',
    component: ListingPage,
    loadData: pageDataLoadingAPI.ListingPage.loadData,
  },
  {
    path: '/purchases',
    name: 'PurchasesPage',
    auth: true,
    authPage: 'LoginPage',
    component: PurchasesListPage,
  },
  {
    path: '/sales',
    name: 'SalesPage',
    auth: true,
    authPage: 'LoginPage',
    component: SalesListPage,
  },
  {
    path: '/user',
    name: 'ProfileBasePage',
    component: RedirectToLandingPage,
  },
  {
    path: '/wallet',
    name: 'WalletPage',
    auth: true,
    authPage: 'LoginPage',
    component: WalletPage,
  },
  {
    path: '/user/:id',
    name: 'ProfilePage',
    component: ProfilePage,
  },
  {
    path: '/profile-settings',
    name: 'ProfileSettingsPage',
    auth: true,
    authPage: 'LoginPage',
    component: ProfileSettingsPage,
  },

  // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
  // so that in the error case users can be redirected back to the LoginPage
  // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
  {
    path: '/login',
    name: 'LoginPage',
    component: AuthenticationPage,
    extraProps: { tab: 'login', isBusiness: false },
  },
  {
    path: '/business_login',
    name: 'BusinessLoginPage',
    component: AuthenticationPage,
    extraProps: { tab: 'login', isBusiness: true },
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: AuthenticationPage,
    extraProps: { tab: 'signup' },
  },
  {
    path: '/confirm',
    name: 'ConfirmPage',
    component: AuthenticationPage,
    extraProps: { tab: 'confirm' },
  },
  {
    path: '/recover-password',
    name: 'PasswordRecoveryPage',
    component: PasswordRecoveryPage,
  },
  {
    path: '/inbox',
    name: 'InboxPage',
    auth: true,
    authPage: 'LoginPage',
    component: InboxPage,
  },
  {
    path: '/inbox/:tab',
    name: 'InboxPageTab',
    auth: true,
    authPage: 'LoginPage',
    component: InboxPage,
    loadData: pageDataLoadingAPI.InboxPage.loadData,
  },
  {
    path: '/user/:id/favorite_listings',
    name: 'FavoriteListingsPage',
    auth: true,
    authPage: 'LoginPage',
    component: FavoriteListingsPage,
  },
  // {
  //   path: '/order/:id',
  //   name: 'OrderPage',
  //   auth: true,
  //   authPage: 'LoginPage',
  //   component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
  // },
  // {
  //   path: '/order/:id/details',
  //   name: 'OrderDetailsPage',
  //   auth: true,
  //   authPage: 'LoginPage',
  //   component: TransactionPage,
  //   extraProps: { transactionRole: 'customer' },
  //   loadData: params => pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
  //   setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
  // },
  // {
  //   path: '/sale/:id',
  //   name: 'SalePage',
  //   auth: true,
  //   authPage: 'LoginPage',
  //   component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
  // },
  // {
  //   path: '/sale/:id/details',
  //   name: 'SaleDetailsPage',
  //   auth: true,
  //   authPage: 'LoginPage',
  //   component: TransactionPage,
  //   extraProps: { transactionRole: 'provider' },
  //   loadData: params => pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
  // },
  {
    path: '/account',
    name: 'AccountSettingsPage',
    auth: true,
    authPage: 'LoginPage',
    component: () => <NamedRedirect name="ContactDetailsPage" />,
  },
  {
    path: '/account/contact-details',
    name: 'ContactDetailsPage',
    auth: true,
    authPage: 'LoginPage',
    component: ContactDetailsPage,
    loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
  },
  {
    path: '/account/change-password',
    name: 'PasswordChangePage',
    auth: true,
    authPage: 'LoginPage',
    component: PasswordChangePage,
  },
  {
    path: '/account/payments',
    name: 'StripePayoutPage',
    auth: true,
    authPage: 'LoginPage',
    component: StripePayoutPage,
    loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
  },
  {
    path: '/account/payments/:returnURLType',
    name: 'StripePayoutOnboardingPage',
    auth: true,
    authPage: 'LoginPage',
    component: StripePayoutPage,
    loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
  },
  {
    path: '/account/payment-methods',
    name: 'PaymentMethodsPage',
    auth: true,
    authPage: 'LoginPage',
    component: PaymentMethodsPage,
    loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfServicePage',
    component: TermsOfServicePage,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: PrivacyPolicyPage,
  },
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: StyleguidePage,
  },
  {
    path: '/styleguide/g/:group',
    name: 'StyleguideGroup',
    component: StyleguidePage,
  },
  {
    path: '/styleguide/c/:component',
    name: 'StyleguideComponent',
    component: StyleguidePage,
  },
  {
    path: '/styleguide/c/:component/:example',
    name: 'StyleguideComponentExample',
    component: StyleguidePage,
  },
  {
    path: '/styleguide/c/:component/:example/raw',
    name: 'StyleguideComponentExampleRaw',
    component: StyleguidePage,
    extraProps: { raw: true },
  },
  {
    path: '/not-found',
    name: 'NotFoundPage',
    component: props => <NotFoundPage {...props} />,
  },

  // Do not change this path!
  //
  // The API expects that the application implements /reset-password endpoint
  {
    path: '/reset-password',
    name: 'PasswordResetPage',
    component: PasswordResetPage,
  },

  // Do not change this path!
  //
  // The API expects that the application implements /verify-email endpoint
  // {
  //   path: '/verify-email',
  //   name: 'EmailVerificationPage',
  //   auth: true,
  //   authPage: 'LoginPage',
  //   component: EmailVerificationPage,
  //   loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
  // },
  {
    path: '/verify-email',
    name: 'VerifyYourEmailPage',
    auth: true,
    authPage: 'LoginPage',
    component: VerifyYourEmailPage,
    loadData: pageDataLoadingAPI.VerifyYourEmailPage.loadData,
  },
  {
    path: '/verify-phone',
    name: 'VerifyYourPhonePage',
    auth: true,
    authPage: 'LoginPage',
    component: VerifyYourPhonePage,
    loadData: pageDataLoadingAPI.VerifyYourPhonePage.loadData,
  },
  {
    path: '/verify-business',
    name: 'VerifyYourBusinessPage',
    auth: true,
    authPage: 'LoginPage',
    component: VerifyYourBusinessPage,
    loadData: pageDataLoadingAPI.VerifyYourBusinessPage.loadData,
  },
  {
    path: '/accept-terms-of-service',
    name: 'AcceptTermsOfServicePage',
    auth: true,
    authPage: 'LoginPage',
    component: AcceptTermsOfServicePage,
  },
];

export default routeConfiguration;
